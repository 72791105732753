@import "./reset.css";

* {
  image-rendering: pixelated;
}

body {
  font-family: Sevener;
  font-size: 12pt;
  line-height: 9pt;
  font-smooth: never;
  -webkit-font-smoothing: none;
  text-rendering: optimizeLegibility;
}

.cursor-default,
.cursor-default button {
  cursor: -webkit-image-set(
        url("./assets/cursors/default.png") 1x,
        url("./assets/cursors/default@2x.png") 2x
      )
      0 0,
    auto;
}

.cursor-move {
  cursor: -webkit-image-set(
        url("./assets/cursors/move.png") 1x,
        url("./assets/cursors/move@2x.png") 2x
      )
      8 8,
    move !important;
}

.cursor-pencil {
  cursor: -webkit-image-set(
        url("./assets/cursors/pencil.png") 1x,
        url("./assets/cursors/pencil@2x.png") 2x
      )
      0 18,
    default !important;
}

.cursor-eraser {
  cursor: -webkit-image-set(
        url("./assets/cursors/eraser.png") 1x,
        url("./assets/cursors/eraser@2x.png") 2x
      )
      3 15,
    default !important;
}

input {
  filter: url(#crispify);
  font-smooth: never;
  -webkit-font-smoothing: none;
  text-rendering: optimizeLegibility;
}

#filters {
  position: fixed;
  left: -10000px;
  top: -10000px;
}

@font-face {
  font-family: "Sevener";
  font-weight: normal;
  src: url("./assets/fonts/Sevener-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Sevener";
  font-weight: bold;
  src: url("./assets/fonts/Sevener-Bold.otf") format("truetype");
}
